import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import { MainContainer } from './MainContainer';


function App() {
  return (
    <MainContainer/>
  );
}

export default App;
